import React, { useState, useEffect, useRef } from 'react';

import { embedDashboard } from 'amazon-quicksight-embedding-sdk';

const getUserAttr = (user: any, attr: string) =>
  user?.signInUserSession?.idToken?.payload?.[attr];

// To make it easier to see all usage flows all logic has been put into one component.
const Dashboard = () => {
  const [dashboard, setDashboard] = useState({} as any);
  const [url, setUrl] = useState(null as any);
  const myContainer = useRef(null);

  useEffect(() => {
    // setUrl(
    //   'https://us-east-1.quicksight.aws.amazon.com/embed/f685945f76cc49dfa5ef6749cbb19a78/dashboards/03164e40-076d-4604-9131-d6dd5990caa5?code=AYABeHdmB3uhrSLxuz5CceQB5CUAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4Q4pdZrmYHprS_1qu_Ad4K1Kwv7kyEcenFNvHMd9HVw8BGJNnqDp3p51-s68XdCiL5gAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDLjRx1GWDWAI-i7WLQIBEIA7S7d_BCpghkO4lkGtjryNSz6_cjjamRwIx0aO4mnYQzpRlof0efFRE5zHs4A9hjWlVXz4mAP6JIHRgHcCAAAAAAwAABAAAAAAAAAAAAAAAAAAs7rQ1WF_WgByPmI0a3ne9f____8AAAABAAAAAAAAAAAAAAABAAAAm-nbhKJF64jrj-CQ751jM67iso5f6mjy_o9zZMyANm-TlgLKSZ92aTT1SQ_Ue-CpooGJhw752e1TH8MoBt8AhG_sfBOV95Tzor2_xx3v9G5W-o7m010F5PZYip9WNPltmC7ov_RH2B3Q72O1JOHG5ig_F0r3MqbfSfWk65SJgXvYQnkCfJF7JOK5lLjO3YkqO4dmXT-QMTS9UCYpVEHRn_rIGP9yz33_R89xHA%3D%3D&identityprovider=quicksight&isauthcode=true'
    // );
    if (url) {
      console.log('myContainer..', myContainer.current);
      console.log('document.getElementById()');
      console.log(document.getElementById('embeddingContainer'));
      setDashboard(
        embedDashboard({
          url: url,
          container: document.getElementById(
            'embeddingContainer'
          ) as HTMLElement,
          // scrolling: 'no',
          iframeResizeOnSheetChange: true, // use this option in combination with height: AutoFit, to allow iframe height to resize dynamically, based on sheet height, on changing sheets.
          width: '100%',
          height: 'AutoFit',
          // locale: 'en-US',
          // footerPaddingEnabled: false,
          // selectedSheetChangeCallback: analyticsSheetChangeCB,
          // parametersChangeCallback: analyticsParametersChangeCB,
        })
      );
    }
  }, [url]);

  function handleSubmit(e: { preventDefault: () => void; target: any }) {
    // Prevent the browser from reloading the page
    e.preventDefault();

    // Read the form data
    const form = e.target;
    const formData = new FormData(form);

    // You can pass formData as a fetch body directly:
    fetch('/some-api', { method: form.method, body: formData });

    // Or you can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    console.log(formJson.myInput);
    setUrl(formJson.myInput);
  }

  return (
    <>
      <form method="post" onSubmit={handleSubmit}>
        <label>
          Text input: <input name="myInput" />
        </label>
        <hr />
        <button type="reset">Reset form</button>
        <button type="submit">Submit form</button>
      </form>
      <div
        id="embeddingContainer"
        ref={myContainer}
        style={{ height: '1000px' }}
      ></div>
    </>
  );
};

export default Dashboard;
