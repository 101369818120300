import Amplify, { MemoryStorage } from '@aws-amplify/core';

// Enable Amplify debugging for better insights when doing local dev.
(window as any).LOG_LEVEL = 'DEBUG';

// Creates a urlOpener function to intercept sign in calls and change the url.
// See docs, https://aws-amplify.github.io/amplify-js/api/interfaces/awscognitooauthopts.html
const createUrlInterceptor = (domain: string, signInUIDomain: string) => (
  url: string
) => {
  const SELF = '_self';
  const loginRequestUrl = `https://${domain}/oauth2/authorize`;
  let windowProxy;

  if (signInUIDomain && url.startsWith(loginRequestUrl)) {
    const signInUrl = url.replace(
      loginRequestUrl,
      `https://${signInUIDomain}/login`
    );
    windowProxy = window.open(signInUrl, SELF);
  } else {
    windowProxy = window.open(url, SELF);
  }
  return windowProxy ? Promise.resolve(windowProxy) : Promise.reject();
};

const configureAmplify = ({
  config,
  useCustomSignInUI,
}: {
  config: any;
  useCustomSignInUI: boolean;
}) => {
  return Amplify.configure({
    Auth: {
      // The use of MemoryStorage is crucial here.
      // By default Amplify stores Gandalf tokens
      // in LocalStorage, which will result in an AppSec finding
      // if your team does not configure Amplify otherwise.
      storage: MemoryStorage,
      region: config.Auth.region,
      userPoolId: config.Auth.userPoolId,
      userPoolWebClientId: config.Auth.userPoolWebClientId,
      oauth: {
        domain: config.Auth.oauth.domain,
        scope: config.Auth.oauth.scope,
        responseType: config.Auth.oauth.responseType,
        redirectSignIn: config.Auth.oauth.redirectSignIn,
        redirectSignOut: config.Auth.oauth.redirectSignOut,
        // (optional) This is used to intercept login requests to navigate to the Custom SignIn UI.
        ...(useCustomSignInUI && {
          urlOpener: createUrlInterceptor(
            config.Auth.oauth.domain,
            config.Gandalf.customSignInDomain
          ),
        }),
      },
    },
  });
};

export default configureAmplify;
