import CryptoJS from 'crypto-js';
import * as randomstring from 'randomstring';
import Cookies from 'js-cookie';

const NONCE = 'nonce';
const NONCE_EXPIRES_IN_DAYS = 1;
const NONCE_GENERATION_LENGTH = 32;

export type RedirectState = {
  redirectUrl: string;
  nonce: string;
};

export const unmarshalRedirectState = (
  state: string | undefined,
  nonce: string | undefined | null = Cookies.get(NONCE)
): RedirectState | null => {
  console.log(state);
  console.log(nonce);
  if (!state || !nonce) {
    return null;
  }

  try {
    const fromBase64 = atob(state);
    const value = CryptoJS.AES.decrypt(fromBase64, nonce).toString(
      CryptoJS.enc.Utf8
    );
    const jsonParsed = JSON.parse(value);

    if (jsonParsed.nonce !== nonce || !jsonParsed.redirectUrl) {
      return null;
    }

    return jsonParsed as RedirectState;
  } catch (err) {
    return null;
  }
};

export const marshalRedirectState = (state: RedirectState): string => {
  const jsonStringified = JSON.stringify(state);
  const encrypted = CryptoJS.AES.encrypt(jsonStringified, state.nonce);
  const base64 = btoa(encrypted.toString());

  return base64;
};

export type GenerateRedirectStateOptions = {
  /**
   * URL to redirect to after auth, defaults to the current host and path with no parameters
   */
  getURL?: () => string;
  /**
   * Generate the nonce for this state, defaults to a 32 length alpha-numeric string
   */
  generateNonce?: () => string;
  /**
   * How to store the nonce generated for this state, defaults to storing in a cookie called nonce with a 1 day expiry
   */
  storeNonce?: (nonce: string) => void;
};

/**
 * Generates the redirect state string to be used in the state param for auth
 *
 * @param options Options for generating redirect state string
 * @returns The generated state string
 */
export const generateRedirectState = ({
  getURL = () => window.location.href.split('?')[0],
  generateNonce = () => randomstring.generate(NONCE_GENERATION_LENGTH),
  storeNonce = (nonce) =>
    Cookies.set(NONCE, nonce, { expires: NONCE_EXPIRES_IN_DAYS }),
}: GenerateRedirectStateOptions): string => {
  const nonce = generateNonce();
  const redirectUrl = getURL();
  storeNonce(nonce);
  const redirectState: RedirectState = { redirectUrl, nonce };
  const marshaled = marshalRedirectState(redirectState);

  return marshaled;
};
