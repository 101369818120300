import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Dashboard from './DashboardOnly';
import './styles.css';

// Modify the config.json to your own Gandalf values.
import config from './config.json';
import { RedirectBoundary } from './RedirectBoundary';

// Change this boolean to control if Gandalf Custom UI should be used.
const useCustomSignInUI = true;

ReactDOM.render(
  <React.StrictMode>
    {/* Obs. Putting the boundary for redirection before the app rendering prevents token exchange */}
    <RedirectBoundary>
      <App config={config} useCustomSignInUI={useCustomSignInUI} />
      {/* <Dashboard /> */}
    </RedirectBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
