import React, { useState, useEffect } from 'react';
import { Hub } from '@aws-amplify/core';
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Signer } from '@aws-amplify/core';
import * as urlLib from 'url';

import shallNotPass from './assets/gandalf-signin.png';
import welcome from './assets/gandalf-happy.jpg';
import { generateRedirectState } from './redirectUtil';
import configureAmplify from './configureAmplify';
import Dashboard from './DashboardOnly';

const getUserAttr = (user: any, attr: string) =>
  user?.signInUserSession?.idToken?.payload?.[attr];

// To make it easier to see all usage flows all logic has been put into one component.
const App = ({
  config,
  useCustomSignInUI,
}: {
  config?: any;
  useCustomSignInUI: boolean;
}) => {
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(false);
  // Configure amplify in the App so that it does not create effects in boundaries before content load
  // Consider creating a seperate context for Amplify to better control this behavior.
  useEffect(() => {
    if (config) {
      configureAmplify({
        config,
        useCustomSignInUI,
      });
    }
  }, [config, useCustomSignInUI]);
  // Setup Amplify Hub listener to receive auth events.
  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          getUser().then((userData) => setUser(userData));
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.error('Sign in failure', data);
          break;
      }
    });
  }, []);

  // Try to get the current signed in user on init.
  useEffect(() => {
    getUser().then((userData) => setUser(userData));
    // logJSONData();
  }, []);

  // function logJSONData() {
  //   // const response = await fetch(
  //   //   'https://ilbp4zhjoj.execute-api.us-west-2.amazonaws.com/test'
  //   // );
  //   // const jsonData = await response.json();
  //   fetch('https://ilbp4zhjoj.execute-api.us-west-2.amazonaws.com/test/pets/')
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error(response.statusText);
  //       }
  //       console.log(response.json());
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   // console.log(jsonData);
  // }

  // sign a request using Amplify Auth and Signer
  function signRequest(url: string, data: any) {
    // the following code is adopted from AWS Amplify Rest Client
    const { search, ...parsedUrl } = urlLib.parse(url, true, true);

    let formattedUrl = urlLib.format({
      ...parsedUrl,
      query: { ...parsedUrl.query },
    });
    Auth.currentCredentials().then((creds) => {
      console.log('creds0');
      console.log(creds);
    });
    return Auth.currentAuthenticatedUser()
      .then((credentials) => {
        let cred = Auth.essentialCredentials(credentials);
        console.log('cred1');
        console.log(cred);

        return Promise.resolve(cred);
      })
      .then((essentialCredentials) => {
        let params = {
          headers: {},
          data: JSON.stringify({
            d: data,
          }),
          method: 'POST',
          url: formattedUrl,
        };

        // cred object keys should stay the same so that
        // Signer.sign function can access the keys
        let cred = {
          secret_key: essentialCredentials.secretAccessKey,
          access_key: essentialCredentials.accessKeyId,
          session_token: essentialCredentials.sessionToken,
        };
        console.log('cred2');
        console.log(cred);
        // set your region and service here
        let serviceInfo = {
          region: 'us-east-1',
          service: 'execute-api',
        };

        // Signer.sign takes care of all other steps of Signature V4
        let signedReq = Signer.sign(params, cred, serviceInfo);

        return Promise.resolve(signedReq);
      });
  }

  function compute() {
    // append query paramaters and values here.
    let url = `https://ppva1k00jd.execute-api.us-east-1.amazonaws.com/default/test-quicksight`;
    let data = {};

    return signRequest(url, data)
      .then((signedRequest) => {
        return fetch(signedRequest.url, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          headers: signedRequest.headers,
          referrer: 'client',
          body: signedRequest.data,
        });
      })
      .then((response) => {
        if (response.ok) {
          return Promise.resolve(response.json());
        } else {
          throw new Error('Failed Request');
        }
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(new Error());
      });
  }

  const getAuthUserToken = async (): Promise<string | null> => {
    const user: any = await Auth.currentAuthenticatedUser();
    console.log(user?.getSignInUserSession().getIdToken().getJwtToken());
    return user?.getSignInUserSession().getIdToken().getJwtToken();
  };

  const getUser = () =>
    Auth.currentAuthenticatedUser().catch(() => {
      console.log('Not signed in');
      setShowLogin(true);
    });

  const handleSignIn = () =>
    Auth.federatedSignIn({
      // If you want to use ESSO with a sepcific provider, you can change this here (ex use case for vanity urls)
      provider: CognitoHostedUIIdentityProvider.Cognito,
      customState: generateRedirectState({}),
    });

  const handleSignOut = () => {
    Auth.signOut().catch((error) => {
      console.error('Issue signing out', error);
    });
  };

  return (
    <>
      <h1>Gandalf LXP Integration Example</h1>
      {!user && showLogin ? (
        <>
          <img src={shallNotPass} alt="Gandalf won't let you pass." />
          <h2>Hi stranger</h2>
          <p>Please login to see your credentials.</p>
          <button className="button" onClick={handleSignIn}>
            Login
          </button>
        </>
      ) : (
        <>
          {/* <img src={welcome} alt="Gandalf is happy to see you." />
          <h2>Welcome {getUserAttr(user, 'name') || '...'}</h2>
          <dl>
            <dt>Vibe ID</dt>
            <dd>
              <code>{getUserAttr(user, 'vibe_user_id') || '...'}</code>
            </dd>
            <dt>Hat ID</dt>
            <dd>
              <code>{getUserAttr(user, 'hat_id') || '...'}</code>
            </dd>
          </dl>
          <button className="button" onClick={getAuthUserToken}>
            send request
          </button> */}
          <Dashboard></Dashboard>
          <button className="button" onClick={handleSignOut}>
            Logout
          </button>
        </>
      )}
    </>
  );
};

export default App;
